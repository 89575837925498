import React, { Component } from "react";
import { Calendar, Globe } from "react-feather";
import {
  AddAbbrevationToTimeZone,
  guessTimeZone,
} from "../services/commonUsefulFunctions";
import TimeZoneSelect from "./timeZoneSelect";
import classNames from "classnames";

class TimeAndTimeZoneText extends Component {
  render() {
    const { isReschedule } = this.props;
    const iconSize = isReschedule ? 14 : 16;
    return (
      <>
        <div
          className={`display-flex-flex-direction-row mt-2.5 font-size-14 items-center font-weight-300`}
          style={{
            textDecoration: this.props.shouldCrossThrough
              ? "line-through"
              : null,
          }}
        >
          <div className="margin-right-10 display-flex items-center">
            <Calendar
              size={iconSize}
              className={this.props.isNewTime ? "color-new-time" : ""}
            />
          </div>

          <div
            className={classNames(
              this.props.isNewTime ? "color-new-time" : "",
              isReschedule ? "font-size-12" : ""
            )}
          >
            {this.props.time}
          </div>
        </div>

        <div
          className={`display-flex-flex-direction-row mt-2.5 font-size-14 items-center font-weight-300`}
          style={{
            textDecoration: this.props.shouldCrossThrough
              ? "line-through"
              : null,
          }}
        >
          <div className="margin-right-10 display-flex">
            <Globe size={iconSize} />
          </div>

          {this.renderTimeZoneSection()}
        </div>
      </>
    );
  }

  renderTimeZoneSection() {
    const {
      selectedTimeZone,
      onSelectTimeZone,
      shouldCrossThrough,
      isReschedule,
    } = this.props;

    if (!onSelectTimeZone || shouldCrossThrough) {
      return (
        <div className={classNames(isReschedule ? "font-size-12" : "")}>
          {AddAbbrevationToTimeZone(selectedTimeZone || guessTimeZone())}
        </div>
      );
    }

    return (
      <div className="default-font-color">
        <TimeZoneSelect
          selectedTimeZone={selectedTimeZone}
          onSelectTimeZone={onSelectTimeZone}
        />
      </div>
    );
  }
}

export default TimeAndTimeZoneText;
