import React, { Component } from "react";
import CustomSelect from "./customSelect";
import {
  AddAbbrevationToTimeZone,
  CreateTimeZoneList,
  createAbbreviationForTimeZone,
  guessTimeZone,
  handleError,
  isEmptyArray,
} from "../services/commonUsefulFunctions";
import { customMenuStyle } from "../lib/reactSelectFunctions";
import {
  TIME_ZONE_SEARCH_QUERY_INDEX,
  countryTimeZones,
  getAllTimeZoneForGMTOffset,
  getMatchingMultipleTimeZoneKey,
  getMatchingSingleTimeZoneCountry,
  getMatchingSingleTimeZoneCountryResult,
  getMatchingTimeZoneForMultipleCountryTZs,
  getOffSetFromGMTSearchString,
  isPartOfPopularTimeZone,
  stringContainsGMTAndNumber,
  timeZonesForCountriesWithMultipleTimeZone,
} from "../services/timeZone";
import {
  GOOGLE_GEOCODING_RESPONSE_STATUS,
  GOOGLE_TIME_ZONE_RESPONSE_STATUS,
  getGeoCodeFromAddress,
  getGoogleMapsAutoComplete,
  getTimeZoneFromLocation,
} from "../lib/googleFunctions";
import _ from "underscore";

export default class TimeZoneSelect extends Component {
  constructor(props) {
    super(props);
    this._searchLocationTimer = null;

    this.state = {
      timeZoneOptions: CreateTimeZoneList(
        this.props.selectedTimeZone || guessTimeZone()
      ),
    };

    this.onInputChange = this.onInputChange.bind(this);
    this.filterOption = this.filterOption.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;
    this.setUpLocationAutoComplete();
  }

  componentWillUnmount() {
    this._isMounted = false;
    clearTimeout(this._searchLocationTimer);
  }
  render() {
    const { selectedTimeZone, onSelectTimeZone } = this.props;
    const timeZone = selectedTimeZone || guessTimeZone();
    return (
      <CustomSelect
        value={{ label: AddAbbrevationToTimeZone(timeZone), value: timeZone }}
        onChange={(option) => {
          const { isAddOn, value } = option;
          if (isAddOn) {
            this.fetchGeoCode(value);
            return;
          }
          if (onSelectTimeZone) {
            onSelectTimeZone(value);
          }
        }}
        options={this.state.timeZoneOptions}
        fontSize={"12px"}
        controllerWidth={224}
        menuListStyle={customMenuStyle({ width: 224 })}
        menuListMaxHeight={200}
        filterOption={this.filterOption}
        onInputChange={this.onInputChange}
      />
    );
  }

  onInputChange(inputText = null) {
    const input = inputText || "";

    this._searchLocationTimer && clearTimeout(this._searchLocationTimer);
    this._searchLocationTimer = null;

    this._searchLocationTimer = setTimeout(
      () => this.searchForLocationSuggestion(input),
      500
    );
  }

  createOriginalTimeZones() {
    return CreateTimeZoneList(this.state.selectedTimeZone || guessTimeZone());
  }

  setUpLocationAutoComplete() {
    this._locationAutocomplete = getGoogleMapsAutoComplete();
  }

  searchForLocationSuggestion(input) {
    if (!input) {
      this.setState({ timeZoneOptions: this.createOriginalTimeZones() });
      return;
    }
    if (!this._isMounted) {
      return;
    }

    if (this._locationAutocomplete) {
      this._locationAutocomplete.getPlacePredictions(
        { input: input, types: ["(cities)"] },
        (predictions) => {
          if (!this._isMounted) {
            return;
          }
          const formattedSuggestions =
            this.formatLocationPredictions(predictions);
          const options = _.clone(this.state.timeZoneOptions);

          // {label, value}
          const filteredOptions = this.getSearchOptions({
            input,
            options,
            formattedSuggestions,
          });
          this.setState({ timeZoneOptions: filteredOptions });
        }
      );
    } else if (!this._locationAutocomplete) {
      this.setUpLocationAutoComplete();
    }
  }

  filterOption(option, inputValue) {
    const { label, value, isAddOn } = option;
    const query = inputValue.toLowerCase();

    let additionalSearchQueries = "";
    if (TIME_ZONE_SEARCH_QUERY_INDEX[value]) {
      additionalSearchQueries = TIME_ZONE_SEARCH_QUERY_INDEX[value];
    }

    if (stringContainsGMTAndNumber(inputValue)) {
      const offset = getOffSetFromGMTSearchString(inputValue);
      if (inputValue.includes("+")) {
        const timeZone = getAllTimeZoneForGMTOffset(`gmt+${offset}`);
        if (timeZone) {
          return timeZone.includes(value);
        }
      } else if (inputValue.includes("-")) {
        const timeZone = getAllTimeZoneForGMTOffset(`gmt-${offset}`);
        if (timeZone) {
          return timeZone.includes(value);
        }
      }
    }

    return (
      isAddOn ||
      label?.toLowerCase().indexOf(query) >= 0 ||
      value?.toLowerCase().indexOf(query) >= 0 ||
      additionalSearchQueries?.indexOf(query) >= 0
    );
  }

  async fetchTimeZone(location) {
    try {
      const response = await getTimeZoneFromLocation(location); // const { lat, lng } = location;
      const output = await response.json();
      if (!this._isMounted) {
        return;
      }

      if (
        output?.status === GOOGLE_TIME_ZONE_RESPONSE_STATUS.OK &&
        output.timeZoneId
      ) {
        // Add what happens if you're a success here
        const updatedTimeZone = {
          value: output.timeZoneId,
          label: output.timeZoneName,
        };
        const updatedOptions =
          this.state.timeZoneOptions.concat(updatedTimeZone);

        this.setState(
          {
            timeZone: updatedTimeZone,
            timeZoneOptions: updatedOptions,
          },
          () => {
            if (this.props.onSelectTimeZone) {
              this.props.onSelectTimeZone(output.timeZoneId);
            }
          }
        );
      } else {
        // do nothing for now
      }
    } catch (error) {
      handleError(error);
    }
  }

  async fetchGeoCode(address) {
    try {
      const response = await getGeoCodeFromAddress(address);
      const output = await response.json();
      if (!this._isMounted) {
        return;
      }

      if (
        output?.status === GOOGLE_GEOCODING_RESPONSE_STATUS.OK &&
        output.results?.[0]?.geometry?.location
      ) {
        // if API reports everything was returned successfully
        this.fetchTimeZone(output.results[0].geometry.location);
      } else {
        // do nothing for now
      }
    } catch (error) {
      handleError(error);
    }
  }

  formatLocationPredictions(predictions) {
    if (isEmptyArray(predictions)) {
      return [];
    }

    return predictions.map((p, index) => {
      return {
        label: `${p.description}`,
        value: p.description,
        isAddOn: true,
      };
    });
  }

  formatMultipleTimeZoneCountries(matches) {
    return matches.map((key) => {
      const countryKey = getMatchingMultipleTimeZoneKey(key);
      const timeZone = timeZonesForCountriesWithMultipleTimeZone[countryKey];
      return {
        value: timeZone,
        label: `(${createAbbreviationForTimeZone(timeZone)}) ${countryKey}`,
      };
    });
  }

  formatSingleTimeZoneCountries(matches) {
    return matches.map((country) => {
      const matchingCountry = getMatchingSingleTimeZoneCountry(country);
      const timeZone = countryTimeZones[matchingCountry];
      return {
        value: countryTimeZones[matchingCountry],
        label: `(${createAbbreviationForTimeZone(
          timeZone
        )}) ${matchingCountry}`,
      };
    });
  }

  getSearchOptions({ input, options, formattedSuggestions }) {
    if (isPartOfPopularTimeZone(input)) {
      return options
        .filter((o) => !o.isAddOn)
        .concat(formattedSuggestions)
        .concat(
          this.formatSingleTimeZoneCountries(
            getMatchingSingleTimeZoneCountryResult(input)
          )
        )
        .concat(
          this.formatMultipleTimeZoneCountries(
            getMatchingTimeZoneForMultipleCountryTZs(input)
          )
        );
    }

    return this.formatSingleTimeZoneCountries(
      getMatchingSingleTimeZoneCountryResult(input)
    )
      .concat(
        this.formatMultipleTimeZoneCountries(
          getMatchingTimeZoneForMultipleCountryTZs(input)
        )
      )
      .concat(options.filter((o) => !o.isAddOn))
      .concat(formattedSuggestions);
  }
}
